#zoom-preview {
  width: 100%;
  height: 100%;
}

div [data-rmiz] {
  width: 100% !important;
  height: 100% !important;
}

div [data-rmiz-content] {
  width: 100% !important;
  height: 100% !important;
}

div [data-rmiz-ghost] {
  width: 100% !important;
  height: 100% !important;
}